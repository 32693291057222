import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '4, 48, 73',
		'primary-dark': '2, 22, 34',
		'accent': '10, 109, 165',
		'accent-plus': '255, 255, 255',
	},
});
