export default {
	cinema: {
		address: 'ул. Камышинская, 43а',
		place: null,
		phoneNumbers: ['61-10-10 (касса)', '61-10-44 (администрация)'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/lunakino',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://apps.apple.com/ru/app/кинотеатр-луна/id1454649189',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.luna',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBFjNKv5tA',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
